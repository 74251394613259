.App {
  text-align: center;
}

/* icons orange fe9000 */


.App-link {
  color: #4E4344;
}
.App-link:hover {
  color: #FBF7EF;
}

/* section {
  min-height: 24px;
  background-color: #91C6B1;
} */

.App-logo-link {
  text-decoration: none !important;
  color: white !important;
  font-weight: bold;
  font-size: larger;

}

.App-logo-link img {
  position: relative;
  top: 16px;
}

/* 
    COLORS
*/
:root {
  --light-background: #91C6B1;
  --normal-background: #579783;
  --dark-background: #658682;
  --link-color: #6d83ab;
  --title-color: #4a5e82;
}

/*
    UTILITY
*/
.flip-hor {
  transform: rotate(180deg);
}
